import { forwardRef } from "react";

import { Image, type ImageProps } from "@chakra-ui/react";

const CustomImage = forwardRef<any, ImageProps>((props, ref) => {
  return <Image loading="lazy" ref={ref} {...props} />;
});
CustomImage.displayName = "CustomImage";

export default CustomImage;
